import React from "react";

import Container from "react-bootstrap/Container";

import Page from "components/Page/Page";

const config = [
  {
    name: "404 heading",
    moduleComponentName: "SectionHeading",
    rowProps: {
      className: "py-5 bg-primary text-light",
      noGutters: true,
    },
    moduleProps: {
      heading: {
        priority: 2,
        text: "Page Not Found",
      },
      subheading: {
        text: `The URL you entered could not be found. Ensure that the URL is correct and try again.`,
      },
    },
    wrapWithContainer: true,
  },
  {
    name: "404 Body",
    moduleComponentName: "SectionText",
    rowProps: {
      className: "text-dark pt-5",
      noGutters: true,
    },
    moduleProps: {
      text: [
        `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Etiam et ex sed sapien porttitor accumsan.
        `,
        `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Etiam et ex sed sapien porttitor accumsan. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Etiam et ex sed sapien porttitor
          accumsan. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Etiam et ex sed sapien porttitor accumsan.
        `,
      ],
    },
    wrapWithContainer: true,
  },
];

const NotFoundPage = () => (
  <Container fluid className="page-view">
    <Page config={config} />
  </Container>
);

export default NotFoundPage;
